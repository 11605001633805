<template>
  <v-app>
    <div>

      <!-- Header Alert -->
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text">
          Jadwal <strong>Praktek</strong> Klinik IONA
        </div>
        <b-button
          squared
          variant="success"
          @click="btnAddOnClick"
          v-b-modal.modal-form
          v-if="manipulateBtn == true"
        >Tambah</b-button>
      </b-alert>

      <div class="row">
        <div class="col-12">
          <Card>
            <template v-slot:body>
              <div>
                <b-tabs content-class="mt-3">
                  <b-tab
                    title="Kalender"
                    active
                    @click="btnTabCalendarOnClick"
                  >
                    <Calendar v-if="renderComponent" />
                  </b-tab>
                  <b-tab title="Tabel">
                    <Table
                      :fields="fields"
                      :items="items"
                      :perPage="perPage"
                      :currentPage="currentPage"
                      :totalRows="totalRows"
                      :hasDoctor="false"
                      @pageOnClick="pageOnClick"
                      @btnEditOnClick="btnEditOnClick"
                      @btnDeleteOnClick="btnDeleteOnClick"
                      @filterDoctorOnSelect="filterDoctorOnSelect"
                    />
                  </b-tab>
                </b-tabs>
              </div>
            </template>
          </Card>
        </div>
      </div>

      <!-- Form Modal -->
      <Form
        :form="form"
        :route="route"
        :editTarget="editTarget"
        :hasDoctor="false"
        @formOnSubmit="formOnSubmit"
      />

    </div>
  </v-app>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import Card from "@/view/content/Card.vue"
import Calendar from '@/component/schedules/Calendar.vue'
import Form from '@/component/schedules/Form.vue'
import Table from '@/component/schedules/Table.vue'

export default {

  name: 'schedule',

  components: {
    Card,
    Table,
    Calendar,
    Form,
  },

  data() {
    return {
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Filter
      filter: {
        doctor_id: ''
      },
      // Form
      form: {
        day_name: '',
        start_time: '',
        end_time: '',
        doctor_id: this.$route.query.doctor_id ?? '',
        duration: '',
      },
      // Form Route
      route: 'schedules',
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: 'doctor_name',
          label: 'Dokter',
          sortable: true,
        },
        {
          key: "day_name",
          label: "Hari",
          sortable: true,
        },
        {
          key: "start_time",
          label: "Jam Mulai",
          sortable: true,
        },
        {
          key: "end_time",
          label: "Jam Selesai",
          sortable: true,
        },
        {
          key: "duration",
          label: "Durasi (Menit)",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Options
      doctors: [],
      // Other
      editTarget: '',
      renderComponent: true,
      manipulateBtn: false,
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let filterParams = `&doctor_id=${this.filter.doctor_id}`
      let response = await module.paginate('schedules', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      this.items = response.data
    },

    btnTabCalendarOnClick() {
      // Remove my-component from the DOM
      this.renderComponent = false
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },

    formOnSubmit() {
      this.btnTabCalendarOnClick()
      this.pagination()
    },

    async filterDoctorOnSelect(doctorId) {
      this.filter.doctor_id = doctorId
      this.pagination()
    },

    btnAddOnClick() {
      this.editTarget = ""
    },

    async btnEditOnClick(id) {
      this.editTarget = id.toString()
      this.form = await module.get('schedules/' + id)
      this.form['_method'] = 'put'
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('schedules/' + id)
      // If Deleted
      if (result) {
        this.$root.$emit('UpdateAvailableDoctor');
        this.pagination()
      }
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        console.log("looping")

        if (access_right[a] == "4002") {
          this.manipulateBtn = true
        }

      }
    },
  },

  mounted() {
    if (this.$route.query.doctor_id) {
      this.$bvModal.show('modal-form')
    }
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Jadwal", route: "" },
      { title: "Semua Jadwal" },
    ])
    // Get Table Data
    this.pagination()
    this.setActiveMenu()
  },

}

</script>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";
.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>